import React from "react"
import tw from "twin.macro"

// Components
import Layout from "@components/layout"
import StaticHero from "@components/staticHero"
import Container from "@components/container"
import Anchor from "@components/Anchor"
import { sectionMd } from "@styles/ui"
import Seo from "@components/seo"

const SMSTermsAndConditionsPage = ({ location }) => {
  location.pathname = "/narcolepsy"

  const headingStyles = tw`font-black text-base mb-2 lg:mb-3.5`
  const paragraphStyles = tw`mb-5 text-base lg:mb-8`

  return (
    <Layout location={location}>
      <Seo
        title="SMS Terms and Conditions | XYWAV®"
        canonicalLocation="/sms-terms-and-conditions/"
      />
      <StaticHero gradient="purple-red">
        <>SMS Terms and Conditions</>
      </StaticHero>

      <section css={sectionMd}>
        <Container>
          <h2 css={headingStyles}>
            XYWAV
            <sup>&reg;</sup> oral solution, 0.5 g/mL Terms &amp; Conditions
          </h2>
          <p css={paragraphStyles}>
            How does the Program work? Individuals can enroll in the SMS Program
            (the “Program”) via an online registration form. Individuals can
            select option to receive SMS text messages. All individuals enrolled
            in the Program will receive an enrollment confirmation text message.
            You can expect to receive up to 10 texts each month during your
            participation in the Program.
          </p>
          <h2 css={headingStyles}>
            Your Consent to These Terms and Conditions:
          </h2>
          <p css={paragraphStyles}>
            By signing up for the SMS Program, you are consenting to the terms
            and conditions set forth below, with respect to receiving autodialed
            text messages sent from or on behalf of Jazz Pharmaceuticals
            (“Jazz”). Providing your phone number and signing up is your
            electronic signature and provides electronic written consent.
            Consent is not a condition of purchase or use of any Jazz product.
            Jazz reserves the right to rescind, revoke or amend the Program
            without notice to you.
          </p>
          <h2 css={headingStyles}>
            Use and Disclosure of Your Personal Information:
          </h2>
          <p css={paragraphStyles}>
            Data obtained from you in connection with the Program may include
            your phone number, related carrier information, and elements of
            pharmacy claim information. This information may be used to
            administer the Program and to provide you with Program benefits such
            as savings offers and Program updates and alerts sent directly to
            your mobile device. Please see our{" "}
            <Anchor
              link="https://www.jazzpharma.com/privacy-statement/"
              isExternal
              newTab
            >
              Privacy Statement
            </Anchor>{" "}
            for more general information on how we may collect, use and disclose
            personal information about you.
          </p>
          <h2 css={headingStyles}>Text Messages Sent to You:</h2>
          <p css={paragraphStyles}>
            The Program is valid with most major US carriers, but not all
            cellular phone providers carry the necessary service to participate.
            Participating Carriers: The service is available on AT&T, Sprint,
            T-Mobile, Verizon Wireless, Boost, Cricket, MetroPCS, U.S. Cellular,
            Virgin Mobile, ACS Wireless, Appalachian Wireless, Bluegrass
            Cellular, Carolina West Wireless, Cellcom, C-Spire Wireless
            (formerly Cellsouth), Cellular One of East Central Illinois,
            Cincinnati Bell Wireless, Cross (dba Sprocket), Duet IP, Element
            Mobile, EpicTouch, GCI Communications, Golden State, Hawkeye (Chat
            Mobility), Hawkeye (NW Missouri Cellular), Illinois Valley Cellular,
            Immix (Keystone Wireless / PC Management), Inland Cellular,
            iWireless, Mobi PCS (Coral Wireless LLC), Mosaic, MTPCS / Cellular
            One (Cellone Nation), Nex-Tech Wireless, nTelos, Panhandle
            Telecommunications, Peoples Wireless, Pioneer, Plateau, Revol
            Wireless, Rina &#8209; Custer, Rina &#8209; All West, Rina &#8209;
            Cambridge Telecom Coop, Rina &#8209; Eagle Valley Comm, Rina &#8209;
            Farmers Mutual Telephone Co, Rina &#8209; Nucla Nutria Telephone Co,
            Rina &#8209; Silver Star, Rina &#8209; South Central Comm, Rina
            &#8209; Syringa, Rina &#8209; UBET, Rina &#8209; Manti, South Canaan
            / CellularOne of NEPA, Thumb Cellular, Union Wireless, United,
            Viaero Wireless, West Central Wireless, Leaco, Nemont/Sagebrush.
            T-Mobile is not liable for delayed or undelivered messages. The
            service is offered on an “as-is” basis and may not be available in
            all areas at all times and may not continue to work in the event of
            product, software, coverage or other changes made by your wireless
            carrier. Carriers are not responsible for delayed or undelivered
            messages. Check your phone capabilities for specific text messaging
            instructions. There is no fee charged to you for receiving Program
            messages that is payable to Jazz or anyone acting on its behalf;
            however, your carrier&apos;s message and data rates may apply
            depending on your individual wireless pricing plan, in accordance
            with your wireless customer agreement. You are responsible for all
            applicable taxes. Consult your wireless service provider regarding
            its pricing plans.
          </p>
          <h2 tw="font-black inline">Participant Opt-Out or Help:</h2>{" "}
          <p tw="inline">
            You may opt-out from participating in the Program at any time. Text{" "}
            <strong>STOP</strong> to 69389 or reply <strong>STOP</strong> to any
            message to opt-out. For help, text <strong>HELP</strong> to 69389 or
            reply <strong>HELP</strong> to any message or call JazzCares
            <sup>&reg;</sup> at{" "}
            <Anchor link="tel:1-833-533-5299">
              <strong>1-833-533-JAZZ (5299)</strong>
            </Anchor>
            .
          </p>
        </Container>
      </section>
    </Layout>
  )
}

export default SMSTermsAndConditionsPage
